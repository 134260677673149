function ArrowCircleDownIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 76 76" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M37.999 69.667c17.489 0 31.666-14.177 31.666-31.666C69.665 20.51 55.488 6.334 38 6.334c-17.49 0-31.667 14.178-31.667 31.667S20.51 69.667 37.999 69.667z"
        stroke="#1A1A1A"
      />
      <path d="M25.332 38l12.667 12.667L50.665 38M38 25.334v25.333" stroke="#1A1A1A" />
    </svg>
  )
}

export default ArrowCircleDownIcon
