import { createContext, ReactChild, useContext, useEffect, useRef } from 'react'
import { ArrowUpRight } from '@vhl/icons'
import { GatsbyImage } from 'gatsby-plugin-image'
import { motion } from 'framer-motion'

interface PodcastContext {
  title: string
  featuredImage?: any
  youtubeLink: string
  spotifyLink: string
}

interface PodcastCardProps extends PodcastContext {
  className?: string
  children?: ReactChild
}

const PodcastContext = createContext<PodcastContext>({
  title: '',
  youtubeLink: '',
  spotifyLink: '',
})

// Podcast Link (Spotify and YouTube)
function PodcastLink({
  className,
  textClassName,
  arrowClassName,
  text,
  type,
}: {
  className?: string
  textClassName?: string
  arrowClassName?: string
  text?: string
  type: 'youtube' | 'spotify'
}) {
  const { spotifyLink, youtubeLink } = useContext(PodcastContext)
  return (
    <a
      target="_blank"
      href={type === 'youtube' ? youtubeLink : spotifyLink}
      className={`flex items-center w-fit space-x-1 border-b-[1px] pb-1 ${className}`}
    >
      <p className={`transition ${textClassName}`}>{text}</p>
      <ArrowUpRight width={24} height={24} className={arrowClassName} />
    </a>
  )
}

function FeaturedImage({
  image,
  className,
  isGatsbyImage,
  openYoutubeLink,
  isOverlay,
}: {
  image?: {
    src: string
    srcSet: string
  }
  className?: string
  isGatsbyImage?: boolean
  openYoutubeLink?: boolean
  isOverlay?: boolean
}) {
  const { title, youtubeLink, featuredImage } = useContext(PodcastContext)
  function openYoutubeLinkInNewTab() {
    window.open(youtubeLink, '_blank')
  }
  function handleClick() {
    if (openYoutubeLink) {
      openYoutubeLinkInNewTab()
    }
  }

  function OverlayWrapper({ children }: { children: ReactChild }) {
    return (
      <div className="relative">
        {children}
        <motion.div
          className="absolute inset-0 hidden lg:block group"
          initial={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
          whileHover={{ backgroundColor: 'rgba(18, 18, 18, 0.50)' }}
        >
          <div className="flex items-center justify-center z-[2] h-full">
            <button
              onClick={() => openYoutubeLinkInNewTab()}
              className="flex items-center justify-center duration-300 scale-0 primary hover:ghost group-hover:scale-100 stroke-white hover:stroke-black"
            >
              <span>Xem trên Youtube</span>
              <ArrowUpRight className="mt-1" height={28} width={28} />
            </button>
          </div>
        </motion.div>
      </div>
    )
  }

  const Image = isGatsbyImage ? (
    <div className={`cursor-pointer ${className}`} onClick={handleClick}>
      <GatsbyImage image={featuredImage} alt={title} />
    </div>
  ) : (
    <div className={`cursor-pointer ${className}`} onClick={handleClick}>
      {image && <img src={image.src} srcSet={image.srcSet} alt={title} />}
    </div>
  )

  if (isOverlay) return <OverlayWrapper>{Image}</OverlayWrapper>
  return Image
}

function YoutubeVideo({ className }: { className?: string }) {
  const { youtubeLink } = useContext(PodcastContext)
  // use youtube iframe api to render video
  const src = `https://www.youtube.com/embed/${youtubeLink.split('=')[1]}`
  const iframeRef = useRef<HTMLIFrameElement>(null)
  useEffect(() => {
    // set iframe height to match 16:9 ratio
    if (iframeRef.current) {
      iframeRef.current.height = `${iframeRef.current.offsetWidth * 0.5625}px`
    }
  }, [iframeRef.current])
  return (
    <div className={className}>
      <iframe
        ref={iframeRef}
        title="youtube-video" // required for iframe
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        // fullwidth
        className="w-full"
      />
    </div>
  )
}

function Title({
  className,
  openYoutubeLink,
}: {
  className?: string
  openYoutubeLink?: boolean
}) {
  const { title, youtubeLink } = useContext(PodcastContext)
  if (openYoutubeLink)
    return (
      <a
        href={youtubeLink}
        className={`${className} transition hover:text-blue-500 duration-300`}
      >
        {title}
      </a>
    )
  return <h1 className={className}>{title}</h1>
}

const PodcastCard = ({
  title,
  youtubeLink,
  spotifyLink,
  featuredImage,
  children,
  className,
}: PodcastCardProps) => {
  return (
    <PodcastContext.Provider
      value={{ title, youtubeLink, spotifyLink, featuredImage }}
    >
      <div className={className}>{children}</div>
    </PodcastContext.Provider>
  )
}

PodcastCard.Title = Title
PodcastCard.YoutubeVideo = YoutubeVideo
PodcastCard.FeaturedImage = FeaturedImage
PodcastCard.PodcastLink = PodcastLink

export const LISTEN_ON_SPOTIFY = 'Nghe trên Spotify'
export const WATCH_ON_YOUTUBE = 'Xem trên Youtube'
export default PodcastCard
